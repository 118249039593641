/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Box from '@mui/material/Box';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';
import {
  ResponsiveScatterPlot,
  type ScatterPlotNodeProps,
  type ScatterPlotRawSerie,
  type ScatterPlotDatum,
} from '@nivo/scatterplot';
import { api } from './api';
import { type GatewayTestResponse, type GatewayCoreResponse, type GatewayStatusAvgUptimeResponse } from './types';

const CustomNodeComponent = ({
  node,
  blendMode,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
  onClick,
}: ScatterPlotNodeProps<ScatterPlotDatum>) => (
  <g transform={`translate(${node.x},${node.y})`}>
    <circle
      r={node.size / 2}
      fill={`rgba(${node.y + 100}, ${200 - node.y}, 0)`}
      style={{ mixBlendMode: blendMode }}
      onMouseEnter={(event) => onMouseEnter?.(node, event)}
      onMouseMove={(event) => onMouseMove?.(node, event)}
      onMouseLeave={(event) => onMouseLeave?.(node, event)}
      onClick={(event) => onClick?.(node, event)}
    />
  </g>
);
export const GatewayTestResults: React.FC<{ identityKey: string }> = ({ identityKey }) => {
  const [data, setData] = React.useState<GatewayTestResponse>();
  const [dataCore, setDataCore] = React.useState<GatewayCoreResponse>();
  const [dataReport, setDataReport] = React.useState<GatewayStatusAvgUptimeResponse>();

  const [lastUpdated, setLastUpdated] = React.useState<Date>();
  const [busy, setBusy] = React.useState(false);
  const [busyMessage, setBusyMessage] = React.useState<string>();
  const [chartData, setChartData] = React.useState<ScatterPlotRawSerie<ScatterPlotDatum>[]>();

  const refreshTests = async () => {
    try {
      const resCore = await api.gatewayCoreCount(identityKey);
      setDataCore(resCore);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting core test metrics', e);
    }
    try {
      const res = await api.gatewayTestResults(identityKey);
      setData(res);
      const series: ScatterPlotRawSerie<ScatterPlotDatum> = {
        id: 'Test runs',
        data: res.data.map((item) => ({
          x: item.timestamp.toISOString(),
          y: item.overall_reliability_for_all_routes_in_monitor_run,
        })),
      };
      setChartData([series]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting test results', e);
    }
    try {
      const res = await api.gatewayAverageUptime(identityKey);
      setDataReport(res);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting test results', e);
    }
  };

  const refresh = async () => {
    try {
      setBusy(true);
      setBusyMessage('Getting latest data...');
      await refreshTests();
      setLastUpdated(new Date());
      setBusyMessage('Finishing refresh...');
    } finally {
      setBusy(false);
    }
  };

  React.useEffect(() => {
    refresh().catch((e: any) => console.error(e));
  }, []);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Timestamp',
        accessorKey: 'timestamp',
        Cell: ({ cell }) => (
          <>
            <strong>
              {formatDistanceToNow(cell.row.original.timestamp, {
                addSuffix: true,
              })}
            </strong>
            <br />
            {cell.row.original.timestamp.toISOString()}
          </>
        ),
      },
      {
        header: 'Overall reliability for all routes in monitor run',
        accessorKey: 'overall_reliability_for_all_routes_in_monitor_run',
        Cell: ({ cell }) => (
          <Box display="flex" alignItems="center" fontSize="inherit">
            <LinearProgress
              sx={{ width: 100, height: 16 }}
              variant="determinate"
              value={cell.row.original.overall_reliability_for_all_routes_in_monitor_run}
            />
            <Typography ml={1} fontSize="inherit">
              {cell.row.original.overall_reliability_for_all_routes_in_monitor_run} %
            </Typography>
          </Box>
        ),
      },
      {
        header: 'Monitor run id',
        accessorKey: 'monitor_run_id',
      },
      {
        header: 'Test route',
        accessorKey: 'test_routes',
        Cell: ({ cell }) => (
          <Stack direction="row" spacing={2}>
            <Button
              sx={{ width: 75 }}
              component="a"
              target="_blank"
              color="success"
              variant="contained"
              size="small"
              href={`/gateway/${identityKey}`}
            >
              {identityKey.slice(0, 6)}
            </Button>
            <ArrowForwardIcon />
            <Button
              sx={{ width: 75 }}
              component="a"
              target="_blank"
              color="primary"
              variant="contained"
              size="small"
              href={`https://explorer.nymtech.net/network-components/nodes/${cell.row.original.test_routes.layer1.node_id}`}
            >
              {cell.row.original.test_routes.layer1.identity_key.slice(0, 6)}
            </Button>
            <ArrowForwardIcon />
            <Button
              sx={{ width: 75 }}
              component="a"
              target="_blank"
              color="primary"
              variant="contained"
              size="small"
              href={`https://explorer.nymtech.net/network-components/nodes/${cell.row.original.test_routes.layer2.node_id}`}
            >
              {cell.row.original.test_routes.layer2.identity_key.slice(0, 6)}
            </Button>
            <ArrowForwardIcon />
            <Button
              sx={{ width: 75 }}
              component="a"
              target="_blank"
              color="primary"
              variant="contained"
              size="small"
              href={`https://explorer.nymtech.net/network-components/nodes/${cell.row.original.test_routes.layer3.node_id}`}
            >
              {cell.row.original.test_routes.layer3.identity_key.slice(0, 6)}
            </Button>
            <ArrowForwardIcon />
            <Button
              sx={{ width: 75 }}
              component="a"
              target="_blank"
              color="success"
              variant="contained"
              size="small"
              href={`/gateway/${identityKey}`}
            >
              {' '}
              {identityKey.slice(0, 6)}
            </Button>
          </Stack>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    initialState: {
      pagination: { pageSize: 25, pageIndex: 0 },
      density: 'compact',
      showColumnFilters: true,
      sorting: [
        {
          id: 'probeScoreCalculated',
          desc: true,
        },
      ],
    },
    data: data?.data || [],
    enableRowSelection: true, // enable some features
    enableColumnOrdering: false, // enable a feature for all columns
    enableGlobalFilter: false, // turn off a feature
  });

  if (!lastUpdated) {
    return (
      <Box mt={2} py={2}>
        <Box mt={4} display="flex" alignItems="center">
          <CircularProgress />
          <Typography fontSize="larger" ml={2}>
            Please watch some 🐼 and 🦊 play in the 🌲🌲🌲 while you wait...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box mt={2} py={2}>
      <Link component={RouterLink} to={`/gateway/${identityKey}/test-results`}>
        <h2>Network Monitor Test Results</h2>
      </Link>
      <Typography paragraph>
        Each test run picks 3 random routes (each route has 3 mixnodes) and sends 3 packets along that route back to
        this gateway. Before the test run starts, the route is verified by sending 1000 packets. If all packets are not
        received, the route is re-randomised.
      </Typography>
      <Typography paragraph>
        If one mixnode in the route is not responding, the overall reliability score for this test run will drop by 33%
        because one of the 3 routes will be routable. And if one or more mixnodes are not responding per run, the score
        will drop by 66%.
      </Typography>
      {dataCore && (
        <Alert severity="info" sx={{ width: 600 }}>
          <Typography paragraph>
            This gateway has been <strong>used in test routes {dataCore.count} times</strong> in the last 30 days.
          </Typography>
          {dataReport && (
            <Typography paragraph>
              The routing score <strong>({dataReport.avg_uptime}%)</strong> of this gateway is calculated as the average
              reliability from test runs that includes this gateway over the last 24 hours.
            </Typography>
          )}
        </Alert>
      )}
      <Box display="flex" justifyContent="space-between" mt={4}>
        <Box>
          {busy ? (
            <Typography>{busyMessage || 'Refreshing...'}</Typography>
          ) : (
            <Button variant="outlined" onClick={refresh}>
              Refresh
            </Button>
          )}
        </Box>
        {lastUpdated && (
          <Stack direction="row" spacing={2}>
            <Typography>Last updated</Typography>
            <Typography>
              <strong>
                {formatDistanceToNow(lastUpdated, {
                  addSuffix: true,
                })}
              </strong>
              <br />
              {lastUpdated.toISOString()}
            </Typography>
          </Stack>
        )}
      </Box>
      {chartData && (
        <Box height={400} mb={2}>
          <ResponsiveScatterPlot
            data={chartData}
            margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%dT%H:%M:%S.%LZ',
            }}
            yFormat={(value) => `${value}% reliability`}
            xFormat={(value) => new Date(value as string).toISOString()}
            yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
            axisBottom={{
              format: (value) =>
                formatDistanceToNow(value, {
                  addSuffix: true,
                }),
            }}
            axisLeft={{
              format: (value) => `${value}%`,
              legend: 'Reliability',
              legendPosition: 'middle',
            }}
            nodeSize={(datum) => (datum.yValue as number) / 5 + 10}
            nodeComponent={CustomNodeComponent}
          />
        </Box>
      )}
      <MaterialReactTable table={table} />
    </Box>
  );
};
