export const minNymNodeVersion = '1.2.0';
export const configCategories = {
  panda: {
    emoji: '🐼',
    minScore: 600,
  },
  dolphin: {
    emoji: '🐬',
    minScore: 500,
  },
  fox: {
    emoji: '🦊',
    minScore: 400,
  },
  mouse: {
    emoji: '🐭',
    minScore: 0,
  },
};

export const probeCategories = {
  peanut: {
    emoji: '🥜',
    minScore: 1200,
  },
  icecream: {
    emoji: '🍦',
    minScore: 1000,
  },
  popcorn: {
    emoji: '🍿',
    minScore: 900,
  },
  lollipop: {
    emoji: '🍭',
    minScore: 300,
  },
};
